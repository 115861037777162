<template>
	<div class="registerForm_box">
		<!-- 注册弹窗 -->
		<el-dialog :title="titlename" center :width="loginwidth" top="10vh" :visible.sync="dialogFormRegister"
			:close-on-click-modal="false" @close="closeLoginform">
			<el-form :model="formObj" :rules="rules">
				<el-form-item label="用户名">
					<el-input v-model="formObj.name" placeholder="只能输入数字和英文" @input="formObj.name=formObj.name.replace(/[^a-zA-Z0-9]/g, '')"></el-input>
				</el-form-item>
				<el-form-item label="设置密码" v-if="istype=='add'">
					<el-input v-model.trim="formObj.password" type="password" placeholder="只允许英文和数字,密码长度是6-18位" @input="formObj.password=formObj.password.replace(/[^a-zA-Z0-9]/g, '')"/>
				</el-form-item>
				<el-form-item label="设置新密码" v-if="istype=='password'">
					<el-input v-model.trim="formObj.password" type="password" placeholder="只允许英文和数字,密码长度是6-18位" @input="formObj.password=formObj.password.replace(/[^a-zA-Z0-9]/g, '')"/>
				</el-form-item>
				<el-form-item label="手机号码">
					<el-input type="number" v-model="formObj.phone" placeholder="请输入手机号码"></el-input>
				</el-form-item>
				<el-form-item label="">
					<div class="flexrow">
						<el-input type="number" v-model="formObj.captcha" placeholder="请输入验证码"></el-input>
						<el-button style="margin-left: 10px;" type="primary" @click="setcaptcha" :disabled="getCodeisWaiting">{{captchatext}}</el-button>
					</div>
				</el-form-item>
				<!-- <el-form-item label="头像" prop="afterstar">
				  <el-upload class="avatar-uploader" action="" :http-request="handlehlImage" :show-file-list="false" :before-upload="beforeAvatarUpload">
				    <img v-if="formObj.hlImage" :src="formObj.hlImage" class="el-avatar">
				    <i v-else class="el-icon-plus avatar-uploader-icon" />
				    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5M</div>
				  </el-upload>
				</el-form-item> -->
				<!-- <el-form-item label="年级">
					<el-select v-model="formObj.grade">
						<el-option v-for="(item, index) in grades" :key="index" :label="item" :value="item" />
					</el-select>
				</el-form-item>
				<el-form-item label="年龄">
					<el-input v-model="formObj.age" placeholder="请输入" type="number"></el-input>
				</el-form-item> -->

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeLoginform">返 回</el-button>
				<el-button type="primary" @click="FormRegister" v-if="istype=='add'">提 交注 册</el-button>
				<el-button type="primary" @click="Formpassword" v-if="istype=='password'">确认修改密码</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		mapState
	} from 'vuex'
	import {
		regionData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";
	import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
	export default {
		name: 'registerForm',
		props: {
			titlename: {
				type: String,
				default: () => {
					return "弹窗";
				}
			},
			isShow: {
				type: Boolean,
				default: () => {
					return false;
				}
			},
			istype: {
				type: String,
				default: () => {
					return "add";
				}
			},
			loginwidth: {
				type: String,
				default: () => {
					return "50%";
				}
			},
		},
		computed: {
			...mapState(['isLogin','bsnName']),
		},
		data() {
			return {
				formLabelWidth: '100%',
				rules: {
					afterstar: [{
						required: true,
						message: '此项为必填项',
						trigger: 'blur'
					}]
				},
				formObj: {
					afterstar: true,
					age: 0,
					gender: "男",
					grade: "一年级",
					name: "",
					nikename: "",
					password: "", //
					phone: '',
					captcha: "", //验证码
					hlImage:"",
				},
				dialogFormRegister: false,
				grades:['一年级','二年级','三年级','四年级','五年级','六年级','初一','初二','初三','高一','高二','高三','大学'],
				captchatext:"发送验证码",
				getCodeisWaiting: false, // 禁止多次点击
			};
		},
		created() {

		},
		watch: {
			isShow(data) {
				this.dialogFormRegister = data
			}
		},
		methods: {
			/**
			 * e 表示长度，默认32位
			 */
			randomString(e) {
				e = e || 32
				var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
				var a = t.length
				var n = ''
				for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
				return n
			},
			//发验证码
			setcaptcha(){
				var _this = this
				if(!this.formObj.phone){
					this.$message.error("请输入手机号")
					return false;
				}
				var params = {}
				params["phone"] = this.formObj.phone
				this.getCodeisWaiting = true //禁止点击
				this.$http.post('app/hlexam/login/captchaPhone', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.$message.success("验证码发送成功")
						//倒计时
						_this.setTimer()
					} else {
						_this.getCodeisWaiting = false
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//倒计时
			setTimer() {
			    var holdTime = 60;
			    this.getCodeisWaiting = true;
			    var Timer = setInterval(() => {
			    if (holdTime <= 0) {
			        this.getCodeisWaiting = false;
			        this.captchatext = "重新获取";
			        clearInterval(Timer);
			        return;
			    }
			    this.captchatext = holdTime + "s可重发";
			    holdTime--;
			    }, 1000);
			},
			//关闭登录弹窗的回调
			closeLoginform() {
				this.$emit("handlezhuceiForm", 'close')
			},
			//注册
			FormRegister() {
				var _this = this
				var params = this.formObj
				if (!params.name) {
					this.$message.error("请输入用户名")
					return;
				}
				if (!params.password) {
					this.$message.error("请输入密码")
					return;
				}
				if (params.password.length<6) {
					this.$message.error("密码长度是6-18位")
					return;
				}
				if (params.password.length>18) {
					this.$message.error("密码长度是6-18位")
					return;
				}
				if (!params.phone) {
					this.$message.error("请输入手机号码")
					return;
				}
				if (!params.captcha) {
					this.$message.error("请输入验证码")
					return;
				}
				params["hlImage"] = require("../../assets/logo_img.png")
				params.nikename = '小河狸-' +this.randomString(8)
				showLoading();
				this.$http.post('app/hlexam/login/register', params).then(function(res) {
					//数据处理
					hideLoading();
					if (res.code == 200) {
						_this.$alert('提交注册成功！', '温馨提示', {
							confirmButtonText: '确定',
							callback: action => {
								_this.closeLoginform()
							}
						});
					}else{
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//修改密码
			Formpassword(){
				var _this = this
				var params = this.formObj
				if (!params.name) {
					this.$message.error("请输入用户名")
					return;
				}
				if (!params.password) {
					this.$message.error("请输入新密码")
					return;
				}
				if (params.password.length<6) {
					this.$message.error("密码长度是6-18位")
					return;
				}
				if (params.password.length>18) {
					this.$message.error("密码长度是6-18位")
					return;
				}
				if (!params.phone) {
					this.$message.error("请输入手机号码")
					return;
				}
				if (!params.captcha) {
					this.$message.error("请输入验证码")
					return;
				}
				var newparams = {
					captcha: params.captcha,
					name: params.name,
					password: params.password,
					phone: params.phone
				}
				showLoading();
				this.$http.post('app/hlexam/login/resetPwd', newparams).then(function(res) {
					//数据处理
					hideLoading();
					if (res.code == 200) {
						_this.$alert('提交修改密码成功，快使用新密码登录吧！', '温馨提示', {
							confirmButtonText: '确定',
							callback: action => {
								_this.closeLoginform()
								_this.$emit("handlesuccess")
							}
						});
					}else{
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			async handlehlImage({
				file
			}) {
				var res = await this.$api.uploadHttp(file, {})
				if (res && res.status == 200) {
					this.formObj.hlImage = res.requestUrls[0]
					this.$message.success('上传图片成功!')
				} else {
					this.$message.error('上传图失败!')
				}
			},
			beforeAvatarUpload(file) {
				// console.log(file)
				const isLt10M = file.size / 1024 / 1024 < 5
				if (!isLt10M) {
					this.$message.error('上传头像图片大小不能超过 5MB!')
					return false
				}
				if (file.type == 'image/jpeg' || file.type == 'image/png') {
					console.log(this.BASEURL)
				} else {
					this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	.form_box {
		padding: 18px;
		background-color: #F9FBFF;
	}

	.formtips {
		font-size: 12px;
		font-weight: 400;
		color: #0D7EFF;
		cursor: pointer;
	}
</style>